import React from 'react';

export default function Home(props) {

      let imgSrc = props.imgSrc ? props.imgSrc : "/img/pov/header.jpg"

      let backgroundStyle = {}
      backgroundStyle.minHeight = '350px'
      backgroundStyle.width = '100%'
      backgroundStyle.WebkitBackgroundSize =  "cover"
      backgroundStyle.MozBackgroundSize = "cover"
      backgroundStyle.OBackgroundSize = "cover"
      backgroundStyle.backgroundSize = "cover"
      backgroundStyle.overflow = "inherit"
      backgroundStyle.position = 'absolute'
      backgroundStyle.top = '0px'
      backgroundStyle.zIndex = -1
      backgroundStyle.background = "url(" + imgSrc + ") center center / cover no-repeat";


      return (
        <div style={backgroundStyle} />
      );

}

import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ScoreColor from '../../data/scoreColor.json';

export default function FeaturedItems(props) {

      let {currentItem} = props

      let envStyle = {fontSize: "12px", fontWeight: "bold", letterSpacing: 2}
      let {score, weight, brand = "NAME BRAND", cat} = currentItem
      envStyle.color = ScoreColor[score]

      return (
        <div className="justify-content-center" style={{padding: "5px"}}>
            <div style={{width: "100%", backgroundColor: "white", borderRadius: "2px", border: props.hideBorder ? null : "1px #dadada solid"}}>
              <div className="justify-content-center align-items-center" >
                <div>
                  <img src={currentItem.imgSrc} width="100%" alt={currentItem.title}/>
                </div>
              </div>

              <div className="align-items-center" style={{height: "20px", width: "100%", overflow: "hidden", marginTop: "20px"}}>
                {currentItem.sustainable &&
                  <img src="/img/sustainableFlag.png" height="20px" alt="sustainableFlag" />
                }
              </div>

              <div style={{padding: "10px"}}>
                  <div style={{ width: "100%", overflow: "hidden", marginTop: "5px"}}>
                    <span style={{fontSize: "20px", fontWeight: "bold"}}>${currentItem.price}</span>
                  </div>
                  <div style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
                    <span style={{fontSize: "12px", color: "grey", textTransform: "uppercase"}}>{brand}</span>
                  </div>
                  <div style={{height: "42px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
                    <span style={{fontSize: "16px", fontWeight: "bold"}}>{currentItem.title}</span>
                  </div>
                  <div style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
                    {cat === "Apparel" ? null : <span style={{fontSize: "10px", color: "grey"}}>{weight}</span> }
                  </div>
                  <div className="align-items-center" style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "10px"}}>

                  </div>
                  <div style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
                    <span style={envStyle}>ENV. IMPACT: {currentItem.score}</span>
                  </div>

                  <div style={{width: "100%", margin: "15px 0px"}} className="justify-content-between">
                    <Button component={Link} disabled={currentItem.disabled} to={ currentItem.disabled ? "" : '/ip/' + currentItem.id} variant="contained" color="secondary" size="small">
                      Buy Now
                    </Button>
                    <Button component={Link} disabled={currentItem.disabled} to={ currentItem.disabled ? "" : '/ip/' + currentItem.id} color="secondary" size="small">
                      View Item
                    </Button>
                  </div>

                </div>

          </div>
        </div>
      )
    }

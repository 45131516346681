import React from 'react';
import BackgroundPOV from '../util/backgroundPOV'
//import { Link} from 'react-router-dom';
import Topbox from './topbox';
import Middlebox from './middlebox';
import BottomBox from './bottombox';

export default function Account(props) {


      return (
        <div className="row" >

            <BackgroundPOV />

            <div className="col-12 row justify-content-center" style={{padding: "10px"}}>

              <div className="col-12 col-md-10 row">
                <div className="col-12">
                  <h1 style={{fontFamily: '"Raleway", sans-serif', margin: "20px 0px", color: "white", fontSize: "48px", fontWeight: "medium"}}>Your SustainaBuy Story</h1>
                </div>

                <Topbox />

                <div className="col-12 row" style={{marginTop: "25px"}}>
                  <Middlebox />
                </div>

                <div className="col-12 row" style={{marginTop: "25px"}}>
                  <BottomBox />
                </div>


              </div>
            </div>



        </div>
      );

}

import React from 'react';
import ItemsData from '../../data/items.json'
import Slider from "react-slick";
import CarouselItem from "../util/carouselItem";

export default function FeaturedItems(props) {


  var settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: props.maxItems ? props.maxItems : 6,
     slidesToScroll: 3,
     responsive: [
       {
         breakpoint: 1524,
         settings: {
           slidesToShow: 5,
           slidesToScroll: 3,
           infinite: true,
           dots: true
         }
       },
       {
         breakpoint: 1224,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 2,
           infinite: true,
           dots: true
         }
       },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
  };

  let items = props.items.map( currentKey=> {

      let currentItem = ItemsData[currentKey]
      return <CarouselItem currentItem={currentItem}  key={currentItem.id} {...props}   />

  })

  return (
      <div className="row col-12" style={{ marginBottom: "20px" }}>
          <div className="col-12" style={{padding:"0px 5px"}}>
             <Slider {...settings}>
               {items}
             </Slider>
           </div>
      </div>
      );
}

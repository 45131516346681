import React from 'react';

export default function Ingredients(props) {

    let {ingredients, SKU, dimensions, weight, cat, fit } = props

    return (
      <div className="row col-12" style={{marginTop: "40px"}}>

        <div className="col-12" style={{height: "1px", backgroundColor: "black", opacity: 0.5}} />

        <div className="col-12">
          <h3 style={{fontWeight: "normal", fontSize: "22px"}}>Ingredients</h3>
        </div>
        <div className="row col-12">
          <p>{ingredients}</p>
        </div>

        <div className="col-12">
          <h3 style={{fontWeight: "normal", fontSize: "22px"}}>Product Details</h3>
        </div>
        <div className="col-12" style={{display: "flex"}}>
          <div style={{width: "225px"}}>

            {cat === "Apparel" ?
            <>Fit <br /><br /></>
            :
            <>
            Assembled Product Weight <br /><br />
            Dimensions <br /><br />
            </>
            }
            SKU <br /><br />
          </div>
          <div style={{flex: 1}}>
            {cat === "Apparel" ?
            <>{fit} <br /><br /></>
            :
            <>
            {weight} <br /><br />
            {dimensions} <br /><br />
            </>
            }
            {SKU} <br /><br />
          </div>
        </div>
      </div>
    )
  }

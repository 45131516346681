import React from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import ScoreColor from '../../data/scoreColor.json';
import {Info, Share} from '@material-ui/icons';


const TrappTip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'whitesmoke',
    maxWidth: 220,
    color: "black",
    fontSize: "12px",
    boxShadow: "0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12)",
    border: '2px solid darkgrey',
    padding: "10px"
  },
}))(Tooltip);

export default function EnvScores(props) {


    let {evnScores} = props
    let listEvnScores = evnScores.map( currentScore => {
      let {title, desc = []} = currentScore
      let showDetails = desc.length > 0 ? true : false

      let score = currentScore.score
      let color = ScoreColor[score]

      let infoDesc
      if (title === "Overall Score"){
        infoDesc = "\"Overall Impact\" score is calculated using the three category impact scores (chemical, water, and climate). This score gives you an idea of a product's overall environmental impact. The lower the score, the more sustainable the product! You can learn more about how we calculate this score on our methodology page."
      } else if (title === "Water Impact"){
        infoDesc = "\"Water Impact\" refers to the amount of freshwater consumed in the manufacturing and/or use of a product. The lower the water impact score, the better the product is for the planet’s water sources! You can learn more about how we calculate this score on our methodology page."
      } else if (title === "Climate Impact"){
        infoDesc = "\"Climate Impact\" refers to the greenhouse gas emissions over the life cycle of the product. The lower the climate impact score, the less impact on our climate! You can learn more about how we calculate this score on our methodology page."
      } else {
        infoDesc = "\"Chemical Impact\" refers to the toxic chemicals contained in a product and/or used in the product’s manufacturing process. The lower the chemical impact score, the safer the product! You can learn more about how we calculate this score on our methodology page."
      }


      return(
        <div className="col-6 col-lg-3 row align-items-start" key={title} style={{padding: "5px"}}>
            <div className="boxShadow" style={{padding: "10px", height: "100%", width: "100%"}} >

                    <div style={{display: "flex", margin: "10px 0px"}}>
                      <div className="align-items-center" style={{flex:1}}>
                        <span style={{fontSize: "14px"}}>{currentScore.title}</span>
                      </div>
                      <div style={{width: "60px"}}>
                        <TrappTip placement="top" interactive title={
                            <React.Fragment>
                              {infoDesc}
                              <br /><br />
                              <Link to="/method">Learn More</Link>
                            </React.Fragment> }>
                           <IconButton
                             size="small" aria-label="view contact analytics" style={{height: "22px", width: "22px", padding: "0px",marginRight: "4px"}}>
                             <Info />
                           </IconButton>
                         </TrappTip>
                        <IconButton size="small">
                          <Share style={{fill: "black"}} />
                        </IconButton>
                      </div>
                    </div>
                    <div className="col-12 justify-content-center" style={{padding: "20px"}}>
                      <div className="numberCircle" style={{border: "15px " + color + " solid", width: "135px", height: "135px", padding: "25px", fontWeight: "bold", fontSize: "45px"}}>{currentScore.score}</div>
                    </div>
                    <div className="col-12" style={{color: showDetails ? "#46AA46": "white", padding: "10px"}}>
                      <span style={{fontWeight: "bold", fontSize: "16px", lineHeight: "24px", letterSpacing: 2}}>PROS</span>
                      <p style={{textTransform: "uppercase", fontSize: "10px", lineHeight: "24px", letterSpacing: 2}}>
                        {desc.map( current => {
                          return  <span key={current}>{current}<br /></span>
                        })}
                      </p>
                    </div>

            </div>
        </div>
              )
            })

    return (
      <div className="row col-12" style={{marginTop: "10px"}}>

        <div className="col-12" style={{height: "1px", backgroundColor: "black", opacity: 0.5}} />

        <div className="col-12">
          <h3 style={{fontWeight: "normal"}}>Environmental Impact</h3>
        </div>
        <div className="row col-12">
          {listEvnScores}
        </div>
      </div>
    )
  }

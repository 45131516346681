import React from 'react';
import ItemsData from '../../data/items.json';
import {Button, ButtonGroup} from '@material-ui/core';
import ScoreColor from '../../data/scoreColor.json';

export default function CartItem(props) {

        let {currentId, lastIndex, index} = props
        let currentItem = ItemsData[currentId]
        let {title, price, imgSrc, brand = "NAME BRAND", sustainable, weight = "00 fl oz", score} = currentItem

        let envStyle = {fontSize: "14px", fontWeight: "bold"}
        envStyle.color = ScoreColor[currentItem.score]

        return(
          <div className="col-12 row " style={{borderBottom: index === lastIndex ? null : "1px #A8A8A8 solid", padding: "30px 0px"}}>
            <div className="col-8 col-md-4 row ">
              <div className="col-6" style={{padding: "0px 20px"}}>
                <img src={imgSrc} style={{width: "100%"}} alt="cart" />
              </div>
              <div className="col-6">
                {sustainable &&
                <div style={{height: "20px", width: "100%", overflow: "hidden", marginBottom: "10px"}}>
                  <img src="/img/sustainableFlag.png" height="20px" alt="sustainableFlag" />
                </div>
                }
                <div style={{ width: "100%", overflow: "hidden", marginBottom: "5px"}}>
                  <span style={{fontSize: "12px", color: "grey", textTransform: "uppercase", letterSpacing: "2px"}}>{brand}</span>
                </div>
                <div style={{ width: "100%", overflow: "hidden", marginTop: "3px", paddingRight: "15px"}}>
                  <span style={{fontSize: "16px", fontWeight: "normal"}}>{title}</span>
                </div>
                <div style={{ height: "18px", width: "100%", overflow: "hidden", marginBottom: "3px"}}>
                  <span style={{fontSize: "10px", color: "grey"}}>{weight}</span>
                </div>
              </div>
            </div>
            <div className="col-3 hidden-sm-down row" style={{padding: "10px"}}>
              <div className="col-12 justify-content-center">
                <div class="numberCircle" style={{border: "15px " + envStyle.color + " solid", width: "100px", height: "100px", padding: "18px", fontWeight: "bold", fontSize: "30px"}}>{score}</div>
              </div>
            </div>
            <div className="col-3 hidden-sm-down row">
              <div className="col-12">
                <div>
                  <div className="col-12 justify-content-center">
                    <ButtonGroup disabled aria-label="item quantity buttons" size="small" color="default">
                      <Button>-</Button>
                      <Button>1</Button>
                      <Button>+</Button>
                    </ButtonGroup>
                  </div>
                  <div className="col-12 justify-content-center">
                    <Button disabled variant="text" style={{textTransform: "none", fontSize: "14px", textDecoration: "underline", paddingLeft: "0px"}}>Remove</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2" style={{paddingLeft: "10px"}}>
              <span style={{fontSize: "18px", fontWeight: "bold"}}>${price}</span>
            </div>
          </div>
        )
      }

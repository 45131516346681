import React from 'react';
import Button from '@material-ui/core/Button';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import ScoreColor from '../../data/scoreColor.json'

export default function Topbox(props) {


      let scoresData = [{id: "score1", title: "Overall Impact", score: 4},
                        {id: "score2", title: "Water Impact", score: 6},
                        {id: "score3", title: "Chemical Impact", score: 2},
                        {id: "score4", title: "Climate Impact", score: 4},
                        ]

      let items = scoresData.map( currentItem => {

        let score = currentItem.score
        let color = ScoreColor[score]

        console.log(color)

        return(
          <div key={currentItem.id} >
              <div style={{padding: "10px 10px 0px 10px"}}>
              <h3 style={{margin: "5px 0px", fontWeight: "normal", width: "100%", textAlign: "center"}}>{currentItem.title}</h3>
              <div className="col-12 justify-content-center" style={{margin: "20px 0px"}}>
                <div class="numberCircle" style={{border: "15px " + color + " solid", width: "150px", height: "150px", padding: "30px", fontWeight: "bold", fontSize: "45px"}}>{currentItem.score}</div>
                </div>
              </div>
          </div>
        )
      })

      const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        //nextArrow: <SampleNextArrow />,
        //prevArrow: <SamplePrevArrow />
      };

      let backgroundStyle = {}
      backgroundStyle.WebkitBackgroundSize =  "cover"
      backgroundStyle.MozBackgroundSize = "cover"
      backgroundStyle.OBackgroundSize = "cover"
      backgroundStyle.backgroundSize = "cover"
      backgroundStyle.overflow = "inherit"
      backgroundStyle.background = "url(/img/account/pov2.png) center top / cover no-repeat";


      return (
        <div className="col-12 row boxShadow" style={{ fontWeight: "semi-bold", fontFamily: "'Raleway', sans-serif"}}>

          <div className="col-3 hidden-md-down" style={backgroundStyle} />

          <div className="col-12 col-lg-9 row" style={{padding: "5px", backgroundColor: "white", paddingBottom: "20px"}}>

            <div className="col-12 col-md-6" style={{padding: "5px 20px"}}>
                <h2 style={{fontSize: "22px"}} >Congrats, You’re a Chemical Crusher!</h2>
                <p style={{fontSize: "12px", color: "#2B2B2B"}} >
                  You’re the Sherlock Holmes of the cleaning aisle, investigating detergents and soaps for sneaky chemicals that can make you and your family sick. You’re dunking on companies that hide their ingredients or use chemicals that can harm your health. Your body thanks you!
                </p><br />
                <p style={{fontSize: "12px", color: "#2B2B2B"}} >
                  But seriously: dangerous chemicals are a serious issue, especially in consumer packaged goods. Exposure to toxic chemicals can increase your chances of developing health disorders or diseases. And those chemicals aren’t exactly easy to spot. Protecting your little ones is even more important because their bodies and brains are still developing. That means every time you buy products that are made with safer ingredients, you’re keeping yourself and your loved ones safe, too.
                </p><br />
                <p style={{fontSize: "12px", color: "#2B2B2B"}} >
                  So keep on keepin’ on, you Chemical Crusher! You’re saving the world, one shopping cart at a time.
                </p>
                <Button variant="outlined" component={Link} to="/method" size="small" style={{marginTop: "10px"}}>
                  Learn More
                </Button>
            </div>
            <div className="col-12 col-md-6 row justify-content-center" style={{padding: "5px 10px 20px 10px"}}>
              <div className="col-12 justify-content-center">
                <div>
                  <h2 style={{fontSize: "22px", marginBottom: "0px"}} >Your Impact Scores This Month</h2>
                </div>
              </div>
            <div className="col-12" style={{padding: "10px", maxWidth: "250px"}}>
                <Slider {...settings}>
                  {items}
                </Slider>
              </div>

            </div>
          </div>
        </div>

      );

}

import React, {useState, useRef} from 'react';

export default function ImpactChart(props) {

      const inputRef = useRef(null);
      const bulletRef = useRef(null);

      let slideRange = (newValue) => {

        setValue(newValue)
        let rangeBullet = bulletRef.current
        let bulletPosition = newValue/9;
        rangeBullet.style.bottom = (bulletPosition * 127) + "px";
        setLabelHTML(labelDiv(newValue))
      }

      let labelDiv = (value) =>{

        return (
          <div className="align-items-center justify-content-center">
            <div className="align-items-center" style={{marginTop: "5px"}}>
              <img height="22px" src="/icon/up_down_arrow.svg" alt="up down arrow" />
              <span style={{fontSize: "12px", marginRight: "5px", marginTop: "2px"}}>GOAL</span>
              <span style={{fontSize: "19px", fontWeight: "bold"}}>{value}</span>
            </div>
          </div>
        )
      }

      //let [chartSelected, setChartSelected] = useState("overall")
      let [value, setValue] = useState(0)
      let [labelHTML, setLabelHTML] = useState(labelDiv(0))

      return (

          <div className="container">

            <div className="range-slider">
              <span ref={bulletRef} id="rs-bullet" className="rs-label">{labelHTML}</span>
              <input ref={inputRef} id="rs-range-line vranger" onChange={ (e)=> slideRange(e.target.value) } className="rs-range" type="range" value={value} min="0" max="9" />
            </div>

          </div>

      );
}

import React from 'react';
export default function Method(props) {

      return (
            <div className="col-12 row justify-content-center" style={{padding: "10px", marginTop: "25px"}}>

              <div className="col-12 col-md-10 row" style={{backgroundColor: "white", padding: "20px", borderRadius: "2px"}}>

                <div className="hidden-lg-down col-3" style={{color: "#4faa3c", padding: "15px"}}>
                    <p style={{marginTop: "540px", fontWeight: "bold", fontSize: "18px", fontStyle: "italic"}}>Consumer products are the single largest source of environmental impact in our modern world.</p>
                </div>

                <div className="col-12 col-xl-6 row" style={{padding: "0px 10px"}}>
                      <div className="col-12">

                      <h1 style={{fontSize: "24px"}}>Methodology</h1>
                      <h2 className="methodSubHead" style={{marginTop: "40px"}}>A Vision for Sustainable Shopping</h2>
                      <p className="methodPara">Creating a transparent online marketplace that offers safe and sustainable products is especially critical today as more consumers move online for their everyday shopping needs. But when it comes to sustainability, people want to know that the information shared with them is legitimate and trustworthy.</p>
                      <p className="methodPara">Having a methodology page on your e-commerce website allows you to be transparent with your customers about where you're getting your sustainability data and how you're using it. Providing this information helps customers understand that you're taking sustainability seriously, which is a good thing: research shows that <a href="https://www.npr.org/2019/12/21/790422167/shopping-for-sustainability">sustainability has become one of shoppers’ top values.</a></p>
                      <p className="methodPara">Focusing on transparency in the e-commerce space is increasingly important as millennials’ and Gen Zers’ spending power increases. Younger shoppers want to buy from brands and companies that are <a href="https://www.entrepreneur.com/article/314156">transparent and committed to making a positive social impact.</a> The principles of SustainaBuy — including adding a methodology page like this one — show your customers that you share their values. And that helps <a href="https://khoros.com/blog/building-trust-through-shared-values-how-brands">develop customer trust</a>, which is good for both your brand and your bottom line.</p>
                      <p className="methodPara">At EDF, we believe that SustainaBuy represents the future of sustainable e-commerce, and we're excited to work with your business to help make sustainable online shopping as easy — and as profitable — as clicking a button.</p>


                      <h2 className="methodSubHead" style={{marginTop: "40px"}}>The SustainaBuy Purpose</h2>
                      <p className="methodPara">Consumer products are the single largest source of environmental impact in our modern world. They are <a href="https://environment.yale.edu/blog/2016/03/yes-consumption-really-does-drive-climate-impacts-and-resource-use">responsible</a> for 60% of global greenhouse gas emissions, 50-80% of land, water and material use, and are one of the <a href="https://www.eea.europa.eu/airs/2018/environment-and-health/production-of-hazardous-chemicals">major sources</a> of human exposure to toxic chemicals. As the global population increases, <a href="https://www.un.org/development/desa/en/news/population/world-population-prospects-2019.html">projected</a> to reach 9.7 billion by 2050, retail consumption and its impact on the planet will increase too.</p>
                      <p className="methodPara">If we're going to fully protect human health and the environment, we have to change people’s relationship to the things they buy.</p>
                      <p className="methodPara">The good news is companies like yours can make it easier to shop sustainably, whether online or in the store, so that every dollar spent goes toward products that are safer for the environment and for the people who use them.</p>
                      <p className="methodPara">Enter SustainaBuy.</p>
                      <p className="methodPara">SustainaBuy’s goal is to demonstrate how easy it is to integrate expertly sourced sustainability data into the online shopping experience. It can be fast, easy and fun for people to make purchases that are better for them and the planet based on real, behind-the-scenes sustainability data about products. Helping people change their shopping habits for the good of their health and the planet can be done with as little friction as possible.</p>
                      <p className="methodPara">By equipping people with the information they need to make safer and more sustainable purchases, together we can change the future of online shopping, one shopping cart at a time.</p>

                      <div className="col-12" style={{margin: "60px 0px", height: "1px", backgroundColor: "black", opacity: 0.5}} />

                      <h2 className="methodSubHead" style={{marginTop: "40px"}}>About Impact Scores</h2>
                      <p className="methodPara">At EDF, we value transparency… and we know you do, too. You've probably noticed that each product you find on our site is given four scores: climate impact, chemical impact, water impact and overall impact. But what do these mean, exactly?</p>

                      <h3 className="methodSubHead" style={{margin: "40px 0px 25px 0px", fontSize: "18px"}}>Climate Impact</h3>
                      <p className="methodPara"><a href="https://climate.nasa.gov/causes/">Greenhouse gas emissions are the leading cause of climate change.</a> These emissions come from many sources — agriculture, deforestation, electricity production, manufacturing processes, product use, and the burning of transportation fuels. When you help your customers purchase products that generate lower emissions throughout their life cycles, you can help curb the amount of greenhouse gases that makes it into the atmosphere, which is an important step in limiting the impact of climate change now and for generations to come.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How We Envision Calculation of this Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >The Climate Impact number you see next to a product is a reflection of that product’s contribution to climate change.  This number should be calculated by considering two things: the amount of greenhouse gases emitted during the lifecycle of a product (and its associated packaging) and the actions the product manufacturer has taken to reduce the product’s greenhouse gas emissions. Data sources should include quantitative and qualitative data provided by the product manufacturer.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How to Read the Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >When you're checking out a product’s Climate Impact, be sure you pay close attention to the number inside the ring. A score of 1 means that the product has the lowest possible Climate Impact in its category. The higher the number, the bigger a product’s Climate Impact. The highest score a product can receive is 10.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>Currently, all numbers shown are for illustrative purposes only.</h4>

                      <h3 className="methodSubHead" style={{margin: "40px 0px 25px 0px", fontSize: "18px"}}>Chemical Impact</h3>
                      <p className="methodPara">Chemicals are all around us. While not all chemicals are dangerous, there are some that can be harmful if we are exposed to them, increasing our risk of allergic reactions, reproductive issues and even cancer. Unfortunately, some of those chemicals (like <a href="https://www.theguardian.com/us-news/2019/may/22/toxic-chemicals-everyday-items-us-pesticides-bpa">formaldehyde and phthalates</a>) are commonly found in consumer products, from detergent and sunscreen to toys and food. When you help your customers buy products free of harmful chemicals, you're helping them make safer choices for themselves and their loved ones.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How We Envision Calculation of this Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >The Chemical Impact number you see next to each product is a reflection of the harmful chemicals present in the item. This number should be determined by considering two things: the presence and amount of harmful chemicals in a product, its production and in its associated packaging; and the actions the product manufacturer has taken to reduce the use of harmful chemicals. Data sources should include quantitative and qualitative data provided by the product manufacturer.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How to Read the Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >When you're checking out a product’s Chemical Impact, be sure you pay close attention to the number inside the ring. A score of 1 means that the product has the lowest possible Chemical Impact in its category. In other words, this product has very few to no harmful chemicals. A higher number indicates a larger presence of harmful chemicals and/or greater concentration. The highest score a product can receive is 10.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>Currently, all numbers shown are for illustrative purposes only.</h4>


                      <h3 className="methodSubHead" style={{margin: "40px 0px 25px 0px", fontSize: "18px"}}>Water Impact</h3>
                      <p className="methodPara">While it’s true that water makes up <a href="https://phys.org/news/2014-12-percent-earth.html">71% of the Earth’s surface</a>, most of it is salinated. In fact, only <a href="https://phys.org/news/2014-12-percent-earth.html">2.5% of water on Earth is drinkable freshwater.</a> When large volumes of freshwater are consumed during the growing, extracting, manufacturing or use of a product, that cuts into one of our most precious finite resources.</p>
                      <p className="methodPara">When you help your customers purchase food and other products that have reduced freshwater consumption in their life cycles, you're helping protect the world’s water sources and making sure there’s enough water for people to thrive.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How We Envision Calculation of this Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >The Water Impact number you see next to each product is a reflection of how much water is consumed in the production and/or use of the item. This number should be determined by evaluating two things: the amount of freshwater consumed in the lifecycle of a product (and its associated packaging) and the actions the product manufacturer has taken to reduce its water usage. Data sources should include quantitative and qualitative data provided by the product manufacturer.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How to Read the Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >When you're checking out a product’s Water Impact, be sure you pay close attention to the number inside the ring. A score of 1 means that the product has the lowest possible Water Impact in its category. The higher the number, the more water a product uses. The highest score a product can receive is 10.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>Currently, all numbers shown are for illustrative purposes only.</h4>


                      <h3 className="methodSubHead" style={{margin: "40px 0px 25px 0px", fontSize: "18px"}}>Overall Impact Score</h3>
                      <p className="methodPara">Some consumers want to know how products fare on specific environmental attributes, like chemicals or climate. But other consumers want to know how a product fares overall. That’s why we created the Overall Impact Score (Overall Score, for short). This helps your customers understand which products will help them make low impacts across the board, maximizing the amount of good they can do with each dollar spent.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How We Envision Calculation of this Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >The Overall Score number you see next to each product is a reflection of how sustainable the product is as a whole. We calculate this number as the average of the sum of a product’s Water, Climate and Chemical Impacts. For example, if a product has a Water Impact of 6, a Climate Impact of 9, and a Chemical Impact of 3, its Overall Score will be 6. When needed, we round the Overall Score scores to the nearest whole number.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>How to Read the Score</h4>
                      <p className="methodPara" style={{marginTop: "0px"}} >When you're checking out a product’s Overall Score, be sure you pay close attention to the number inside the ring. A score of 1 means that the product has the lowest possible environmental impact in its category. Put another way, products with low Overall Scores are good for the climate, water and health. The higher the Overall Score number, the less sustainable the product. The highest score a product can receive is 10.</p>

                      <h4 className="methodSubHead" style={{marginTop: "20px", marginBottom: "2px", fontSize: "14px"}}>Currently, all numbers shown are for illustrative purposes only.</h4>

                      <h2 className="methodSubHead" style={{marginTop: "40px"}}>Now What?</h2>
                      <p className="methodPara">How can you make SustainaBuy a reality for your company? EDF’s experts are here to help. Contact us <a href="https://business.edf.org/contact/">here.</a></p>
                      <p className="methodPara">You can learn more about sustainable e-commerce at <a href="https://business.edf.org/ecommerce.">https://business.edf.org/ecommerce.</a></p>

                      <h2 className="methodSubHead" style={{marginTop: "40px"}}>About Environmental Defense Fund</h2>
                      <p className="methodPara"><a href="http://www.edf.org/">Environmental Defense Fund</a>, a leading international nonprofit organization, creates transformational solutions to the most serious environmental problems. EDF links science, economics, law and innovative private-sector partnerships. Connect with us on <a href="https://business.edf.org/">EDF+Business</a> and <a href="https://twitter.com/EDFbiz">Twitter.</a></p>

                      <div style={{height: "80px"}} />

                      </div>
                  </div>

                  <div className="hidden-lg-down col-3" style={{color: "#4faa3c", padding: "15px"}}>
                      <p style={{marginTop: "50px", fontWeight: "bold", fontSize: "18px", fontStyle: "italic"}}>Focusing on transparency in the e-commerce space is increasingly important as millennials’ and Gen Zers’ spending power increases. Younger shoppers want to buy from brands and companies that are transparent and committed to making a positive social impact.</p>
                      <p style={{marginTop: "630px", fontWeight: "bold", fontSize: "18px", fontStyle: "italic"}}>SustainaBuy’s goal is to demonstrate how easy it is to integrate expertly sourced sustainability data into the online shopping experience. It can be fast, easy and fun for people to make purchases that are better for them and the planet based on real, behind-the-scenes sustainability data about products.</p>
                  </div>


              </div>

        </div>
      );

}

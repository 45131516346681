import React, {useState} from 'react';
import {Button, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import {Link} from 'react-router-dom';

export default function CartTotals(props) {

      let [deliveryMethod, setDeliveryMethod] = useState(1)

      return (
        <div className="col-12 row" style={{border: "1px solid #BDBDBD", borderRadius: "1px", backgroundColor: "#EBEBEB", fontSize: "14px"}}>

            <div className="col-12" style={{padding: "10px"}}>
              <Button disabled fullWidth variant="contained" color="primary">Checkout</Button>
            </div>

            <div className="col-12" style={{padding: "10px", fontWeight: "bold", fontSize: "14px"}}>
              Order Summary
            </div>
            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px", color: "#4B4B4B"}}>
                Subtotal
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "15px"}}>
                $37.63
              </div>
            </div>
            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px", color: "#4B4B4B"}}>
                Shipping
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "15px"}}>
                $0.00
              </div>
            </div>
            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px", color: "#4B4B4B"}}>
                Sales Tax
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "15px"}}>
                $2.26
              </div>
            </div>

            <div className="col-12" style={{height: "1px", backgroundColor: "#BDBDBD", margin: "10px 0px" }} />

            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "13px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                Order Total
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "18px"}}>
                $39.89
              </div>
            </div>

            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px", fontWeight: "bold"}}>
                SustainaBuy Points <br />
                <Link to="method" style={{ fontSize: "12px", fontWeight: "normal", textDecoration: "underline", color: "#FC766E", paddingLeft: "0px"}}>Learn More</Link>
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "18px", color: "#24C531"}}>
                +43
              </div>
            </div>

            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px"}}>
                <span style={{fontWeight: "bold"}}>Payment Information</span><br />
                <span style={{color: "#4B4B4B"}}>Visa Ending in ...3467</span>
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "18px", color: "#24C531"}}>
                <span style={{ fontSize: "12px", fontWeight: "normal", textDecoration: "underline", color: "#FC766E", paddingLeft: "0px"}}>Edit</span>
              </div>
            </div>

            <div className="col-12" style={{height: "1px", backgroundColor: "#BDBDBD", margin: "10px 0px" }} />

            <div className="col-12" style={{fontSize: "12px", fontWeight: "bold", padding: "10px"}}>
              Order Environmental Impact  <br />
              <p style={{ fontSize: "12px", fontWeight: "normal", textDecoration: "underline", paddingLeft: "0px"}}>View detailed report</p>
            </div>

            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-6 justify-content-center align-items-center">
                <div class="numberCircle" style={{border: "15px #4BAB34 solid", width: "100px", height: "100px", padding: "18px", fontWeight: "bold", fontSize: "30px"}}>2</div>
              </div>
              <div className="col-6 justify-content-center align-items-center" style={{fontSize: "16px", fontWeight: "bold"}}>
                <p style={{textAlign: "center"}}>You're an Enviro Ally!</p>
              </div>
            </div>

            <div className="col-12" style={{height: "1px", backgroundColor: "#BDBDBD", margin: "10px 0px" }} />

            <div className="col-12 row" style={{padding: "10px"}}>
              <div className="col-8" style={{fontSize: "12px"}}>
                <span style={{fontWeight: "bold"}}>Shipping Information </span>
              </div>
              <div className="col-4 justify-content-end" style={{fontWeight: "bold", fontSize: "18px", color: "#24C531"}}>
                <span style={{ fontSize: "12px", fontWeight: "normal", textDecoration: "underline", color: "#FC766E", paddingLeft: "0px"}}>Edit</span>
              </div>
            </div>

            <div className="col-12" style={{fontSize: "12px", color: "#4B4B4B", padding: "10px"}}>
              Rachel Smith<br />
              1875 Connecticut Avenue NW<br />
              Ste 600<br />
              Washington, D.C. 20009
            </div>

            <div className="col-12" style={{padding: "15px 10px"}}>
              <FormControl variant="outlined" fullWidth>
                <Select value={deliveryMethod} onChange={e => setDeliveryMethod(e.target.value)} style={{backgroundColor: "white", fontSize: "12px"}}
                        id="demo-simple-select-outlined" size="small">
                  <MenuItem value={1} style={{fontSize: "12px"}} disabled>Select Delivery Method</MenuItem>
                  <MenuItem value={10} style={{fontSize: "12px"}}>Local Pick Up (Same Day)</MenuItem>
                  <MenuItem value={20} style={{fontSize: "12px"}}>Standard Delivery (4-8 Days)</MenuItem>
                  <MenuItem value={30} style={{fontSize: "12px"}}>Expedited Delivery (2 Days)</MenuItem>
                  <MenuItem value={40} style={{fontSize: "12px"}}>Request Zero-Emission Delivery</MenuItem>
                  <MenuItem value={50} style={{fontSize: "12px"}}>Ship Fewest Packages as Possible</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 row" style={{fontSize: "12px", backgroundColor: "#24C531", color: "white", padding: "10px"}}>

              <div className="col-8" style={{fontSize: "14px", padding: "10px 0px"}}>
                <span style={{fontWeight: "bold"}}>Offset Your Impact</span>
              </div>

              <div className="col-12">
                Add a one-time <span style={{fontWeight: "bold"}}>Carbon Offset</span> to your order?
              </div>

              <div className="col-12 row" style={{marginTop: "10px"}}>
                <div className="col-6" style={{fontSize: "12px"}}>
                  <span style={{fontWeight: "bold"}}>Only</span><br />
                  <span style={{fontWeight: "bold", fontSize: "20px"}}>$5.00</span>
                </div>
                <div className="col-6 align-items-center">
                  <Button disabled variant="contained" color="primary" size="small">Buy Now</Button>
                </div>
              </div>

            </div>

        </div>
      );

}

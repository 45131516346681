import React from 'react';
import FeaturedItems from './featuredItems'
import LargeCard from '../util/cards/largeCard'
import BackgroundPOV from '../util/backgroundPOV'
//import { Link} from 'react-router-dom';
import CategoriesData from '../../data/categories.json'
import LargeCardsData from '../../data/largeCards.json'

export default function CategoryPage(props) {

      let currentCat = CategoriesData[props.id]
      let {topCards, bottomCards, items} = currentCat

      return (
        <div className="row">

            <BackgroundPOV />

            <div className="col-12 row justify-content-center" style={{padding: "20px 10px"}}>

              <div className="col-12 col-md-10 row">

                <div className="col-12">
                  <h1 style={{fontFamily: '"Raleway", sans-serif', margin: "10px", color: "white", fontSize: "48px", fontWeight: "medium"}}>{currentCat.title}</h1>
                </div>

                <LargeCard current={LargeCardsData[topCards[0]]} />
                <LargeCard current={LargeCardsData[topCards[1]]} />

                <div className="col-12" style={{marginTop: "10px"}}>
                  <h3 style={{fontSize: "26px", fontWeight: "normal", margin: "15px 5px 20px 10px"}}>Featured Items</h3>
                </div>

                <FeaturedItems items={items} {...props} />

                <LargeCard current={LargeCardsData[bottomCards[0]]} />
                <LargeCard current={LargeCardsData[bottomCards[1]]} />

              </div>
            </div>



        </div>
      );

}

import React, {useEffect} from 'react';
import './style/boottrapp.css'
import './App.css'
import './vertslider.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Homepage from './components/homepage/homepage';
import CategoryPage from './components/categoryPage/categoryPage';
import ItemPage from './components/itemPage/itemPage';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Account from './components/account/account';
import Cart from './components/cart/cart';
import Method from './components/method/method';
import VisualSearch from './components/visualSearch/visualSearch';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


export default function App() {

  let muiTheme = createMuiTheme({
        palette: {
          secondary: { main: '#FF8C80', contrastText: '#ffffff'},
          primary: { light: '#a8d0e6', main: '#FF8C80', contrastText: '#fff'},
        },
        typography: { useNextVariants: true },
      });

  return (
    <Router onUpdate={() => console.log("asf")}>
        <ScrollToTop />
        <MuiThemeProvider theme={muiTheme}>

          <Route path={'/'} render={(props) => ( <Navbar {...props}/> )} />

          <Switch>
              <Route exact path={'/'} render={(props) => (<Homepage />)}/>
              <Route exact path={'/cp/:id'} render={(props) => (<CategoryPage  id={props.match.params.id} />)}/>
              <Route exact path={'/ip/:id'} render={(props) => (<ItemPage  id={props.match.params.id} />)}/>
              <Route exact path={'/account'} render={(props) => (<Account />)}/>
              <Route exact path={'/cart'} render={(props) => (<Cart />)}/>
              <Route exact path={'/method'} render={(props) => (<Method />)}/>
              <Route exact path={'/search'} render={(props) => (<VisualSearch />)}/>

              <Route path='*' exact={true} render={(props) => (<div className="pagePadding">Page not found</div>)}/>
          </Switch>

          <Switch>
            <Route path={'/search'} render={null} />
            <Route path={'/'} render={(props) => ( <Footer {...props}/> )} />
          </Switch>

        </MuiThemeProvider>
    </Router>
  );
}

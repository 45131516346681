import React from 'react';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import {Search} from '@material-ui/icons';

export default function PovSearch(props) {

  return(
        <div className="col-12 row justify-content-center">

            <div className="col-8" style={{borderRadius: "40px", backgroundColor: "white", display: "flex", alignItems: "center"}}>

                <div style={{width: "110px", paddingLeft: "10px", borderRight: "1px #ddd solid", height: "100%", display: "flex", alignItems: "center"}}>
                  <span style={{fontSize: "12px", opacity: "50%"}}>EVERYTHING</span>
                  <span style={{fontSize: "9px", opacity: "50%", padding: "5px"}}>&#x25BC;</span>
                </div>
                <div style={{flex: 1, padding: "0px 10px", textAlign: "center"}}>
                  <InputBase fullWidth style={{ textAlign: "center"}}
                    inputProps={{ 'aria-label': 'search', width: "100%" }}
                  />
                </div>
                <div className="align-items-center justify-content-center" style={{width: "65px", backgroundColor: "#E0E0E0", borderRadius: "40px", height: "100%", border: "1px grey solid"}}>
                    <Button aria-label="menu" size="small" style={{borderRadius: "40px", height: "100%"}} disabled>
                      <Search />
                    </Button>
                </div>
            </div>

        </div>

      );

}

import React from 'react';
import CartItem from './cartItem';
import CartTotals from './cartTotals';

export default function Cart(props) {


      let itemsInCart = ["2700", "2702", "2704"]

      let showItems = itemsInCart.map( (currentId, index) => {
        return <CartItem currentId={currentId} key={currentId} lastIndex={2} index={index} />
      })

      let savedItems = ["2705", "conc"]

      let showSavedItems = savedItems.map( (currentId, index) => {
        return <CartItem currentId={currentId} key={currentId} lastIndex={1} index={index} />
      })


      return (
        <div className="col-12 row justify-content-center" style={{padding: "20px 10px"}}>

          <div className="col-12 col-md-11 col-lx-10 row">

            <div className="col-12">
              <h3 style={{margin: "0px 10px 10px 10px", fontWeight: "normal", fontSize: "32px", padding: "15px 0px"}}>Shopping Cart</h3>
            </div>

            <div className="col-12 row align-items-start" style={{backgroundColor: "white", padding: "30px 20px", border: "1px solid #A8A8A8"}}>

                <div className="col-12 col-lg-9 row" style={{padding: "20px 20px 20px 0px"}}>
                  <div className="col-8 col-md-4">
                    Product Details
                  </div>
                  <div className="col-3 hidden-sm-down">
                    <div className="justify-content-center">
                    Environmental Impact
                    </div>
                  </div>
                  <div className="col-3 hidden-sm-down">
                    <div className="justify-content-center">
                    Qty.
                    </div>
                  </div>
                  <div className="col-2" style={{paddingLeft: "10px"}}>
                    Total
                  </div>
                  {showItems}
                </div>
                <div className="col-12 col-lg-3 row">
                  <CartTotals />
                </div>

            </div>

            <div className="col-12" style={{marginTop: "40px"}}>
              <h3 style={{margin: "0px 10px 10px 10px", fontWeight: "normal", fontSize: "32px", padding: "15px 0px"}}>Saved for Later</h3>
            </div>

            <div className="col-12 row align-items-start" style={{backgroundColor: "white", padding: "30px 20px", border: "1px solid #A8A8A8"}}>

                <div className="col-12 col-lg-9 row" style={{padding: "20px 20px 20px 0px"}}>
                  <div className="col-8 col-md-4">
                    Product Details
                  </div>
                  <div className="col-3 hidden-sm-down">
                    <div className="justify-content-center">
                    Environmental Impact
                    </div>
                  </div>
                  <div className="col-3 hidden-sm-down">
                    <div className="justify-content-center">
                    Qty.
                    </div>
                  </div>
                  <div className="col-2" style={{paddingLeft: "10px"}}>
                    Total
                  </div>
                  {showSavedItems}
                </div>
                <div className="hidden-md-down col-3 col-12" />

            </div>


          </div>
        </div>
      );

}

import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import CategoriesData from '../../data/categories.json'
import Button from '@material-ui/core/Button';

export default function Home(props) {

  var settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: 6,
     slidesToScroll: 1,
     responsive: [
       {
         breakpoint: 1684,
         settings: {
           slidesToShow: 5,
           slidesToScroll: 1,
           infinite: true,
           dots: true
         }
       },
       {
         breakpoint: 1424,
         settings: {
           slidesToShow: 4,
           slidesToScroll: 1,
           infinite: true,
           dots: true
         }
       },
        {
          breakpoint: 1124,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
  };

  let categoriesKeys  = CategoriesData ? Object.keys(CategoriesData) : []
  let items = categoriesKeys.map( currentKey=> {

      let current = CategoriesData[currentKey]
      return (
        /*
        <div key={current.id}>
        <div className="justify-content-center" style={{padding: "5px"}}>
            <div style={{width: "100%", backgroundColor: "white", borderRadius: "2px", border: "1px #dadada solid"}}>
                <div className="image-wrapper">
                  <img src={current.imgSrc} width="100%" alt={current.title}/>
                </div>
                <div style={{height: "90px", width: "100%", padding: "10px", backgroundColor: "white"}}>
                  <span style={{fontWeight: "normal", fontSize: "14px"}}>{current.title}</span>
                  <div style={{padding:"10px 0px"}}>
                    <Button component={Link} disabled={current.disabled} to={ current.disabled ? "/" : "/cp/" + current.id} variant="contained" color="primary" size="small">
                      Shop Now
                    </Button>
                  </div>
                </div>
            </div>
        </div>
        </div>
        */
        <div key={current.id} style={{padding: "10px"}}>
          <div style={{padding: "10px"}}>
          <div className="col-12 row" style={{backgroundColor: "white", borderRadius: "2px", border: "1px #dadada solid"}} key={current.id}>
            <div className="image-wrapper" style={{height: "225px", overflow:"hidden"}}>
              <img src={current.imgSrc} alt={current.title} style={{overflow: "hidden", objectFit: "cover"}} />
            </div>
            <div style={{height: "90px", width: "100%", padding: "10px", backgroundColor: "white"}}>
              <span style={{fontWeight: "normal", fontSize: "14px"}}>{current.title}</span>
              <div style={{padding:"10px 0px"}}>
                <Button component={Link} disabled={current.disabled} to={ current.disabled ? "/" : "/cp/" + current.id} variant="contained" color="primary" size="small">
                  Shop Now
                </Button>
              </div>
            </div>
          </div>
          </div>
          </div>

      )
  })

  return (
      <div className="row col-12" style={{margin: "20px 0px 40px 0px"}}>
          <div className="col-12">
            <h3 style={{fontSize: "26px", fontWeight: "normal", margin: "15px 5px 20px 10px"}}>Shop By Category</h3>
          </div>

          <div className="col-12" style={{padding: "0px 5px"}}>
             <Slider {...settings}>
               {items}
             </Slider>
           </div>
      </div>
      );
}

import React from 'react';
import {Link} from 'react-router-dom';
import FeaturedItems from '../categoryPage/featuredItems'
import Button from '@material-ui/core/Button';
import ItemsData from '../../data/items.json';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EnvScores from './envScores';
import Ingredients from './ingredients';
import ScoreColor from '../../data/scoreColor.json';

export default function ItemPage(props) {

      let currentItem = ItemsData[props.id]
      let {title, price, desc, environmentalcCerts = [], cat, subCat, imgAltSrc, brand, evnScores, ingredients, scorePros, weight = "00 fl oz", dimensions = "", SKU = "", fit = ""} = currentItem

      let envStyle = {fontSize: "12px", fontWeight: "bold", letterSpacing: 2}
      let score = currentItem.score
      envStyle.color = ScoreColor[score]


      return (
        <div className="col-12 row justify-content-center">
          <div className="col-12 col-md-10 row align-items-start" style={{backgroundColor: "white", padding: "20px"}}>

            <div className="col-12" style={{margin: "10px 0px"}}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <span color="textPrimary" style={{opacity: "87%", fontSize: "13px", color: "black"}}>Home</span>
                  <span color="textPrimary" style={{opacity: "87%", fontSize: "13px", color: "black"}}>{cat}</span>
                  <span color="textPrimary" style={{opacity: "87%", fontSize: "13px", color: "black"}}>{subCat}</span>
                  <span color="textPrimary" style={{opacity: "87%", fontSize: "13px", color: "black"}}>{brand}</span>
             </Breadcrumbs>
            </div>

            <div className="col-12 col-md-6">
                <img src={imgAltSrc} width="100%" alt="alternative" />
            </div>

            <div className="col-12 col-lg-6 row align-items-start">
                <div className="col-12 col-md-6 row">

                    {currentItem.sustainable &&
                    <div className="align-items-center" style={{height: "20px", width: "100%", overflow: "hidden", marginTop: "5px"}}>
                      <img src="/img/sustainableFlag.png" height="20px" alt="sustainableFlag" />
                    </div>
                    }

                    <div style={{ width: "100%", overflow: "hidden", margin: "10px 0px 5px 0px"}}>
                      <span style={{fontSize: "16px", color: "grey", textTransform: "uppercase", letterSpacing: "2px"}}>{brand}</span>
                    </div>
                    <div style={{ width: "100%", overflow: "hidden", marginTop: "3px"}}>
                      <span style={{fontSize: "26px", fontWeight: "normal"}}>{title}</span>
                    </div>
                    <div style={{ height: "18px", width: "100%", overflow: "hidden", marginBottom: "3px"}}>
                      <span style={{fontSize: "10px", color: "grey"}}>{weight}</span>
                    </div>
                    <div style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
                      <span style={envStyle}>ENV. IMPACT: {currentItem.score}</span>
                    </div>

                </div>
                <div className="col-12 col-lg-6 row" style={{padding: "5px"}}>

                    <div className="justify-content-between" style={{ width: "100%", overflow: "hidden", padding: "5px 0px"}}>
                      <span style={{fontSize: "28px", fontWeight: "bold"}}>${price}</span>
                      <Button size="small" component={Link} to={'/cart'} color="primary" variant="contained"  style={{marginTop: "4px", height: "28px", fontSize: "12px", padding: "0px 20px"}}>
                        Buy Now
                      </Button>
                    </div>
                    <div style={{ width: "100%", overflow: "hidden", marginTop: "15px"}}>
                      Qty: <select><option>1</option><option>2</option><option>3</option></select>
                    </div>
                    <Button color="primary" size="small" component={Link} to={'/cart'} variant="outlined" fullWidth style={{marginTop: "15px", height: "30px", fontSize: "12px"}}>
                      Add To Cart
                    </Button>
                </div>

                { environmentalcCerts.length > 0 &&
                <div className="col-12">

                  <h3 style={{color:"grey", fontSize: "12px"}}>Environmental Certifications</h3>

                  {environmentalcCerts.map( (current)=> {
                      return( <img width="75" src={"/certs/" + current} key={current} alt={current} style={{marginRight: "15px"}} />)
                    })
                  }

                </div>
                }

                <div className="col-12" style={{marginTop: "25px", fontSize: "12px"}}>
                  {desc.map( (current, index)=> {
                      return( <span key={index}><p>{current}</p><br /></span>)
                    })
                  }
                </div>

            </div>

            <div className="col-12 row">
              <EnvScores evnScores={evnScores} scorePros={scorePros} />
            </div>

            <div className="col-12 row">
              <Ingredients ingredients={ingredients} desc={desc} dimensions={dimensions} weight={weight} SKU={SKU} cat={cat} fit={fit} />
            </div>

            <div className="col-12" style={{height: "1px", backgroundColor: "black", opacity: 0.5}} />

            <div className="col-12">
              <h3 style={{fontWeight: "normal", fontSize: "22px"}}>Customers Also Bought</h3>
            </div>

            <div className="col-12 row" style={{marginBottom: "20px"}}>
              <FeaturedItems items={currentItem.reccoItems} hideBorder={true} maxItems={5} />
            </div>

          </div>
        </div>
      );

}

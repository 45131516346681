import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import { ArrowForwardIos } from '@material-ui/icons';

export default function Topbox(props) {

      let boxesData = [ {id: "ws", title: "Water Saved", data: 123, label: "GAL"},
                        {id: "chem", title: "Toxics Avoided", data: "45", label: null},
                        {id: "purch", title: "Sustainable Purchases", data: "65%", label: null},
                        {id: "tree", title: "Cars Off the Road", data: 10, label: null}
                      ]

      let showBoxes = boxesData.map( current => {
        return(
          <div className="col-md-12 col-lg-6" style={{padding: "5px"}} key={current.id}>
            <div style={{border: "1px #ddd solid", borderRadius: "3px", padding: "5px 5px 10px 5px", height: "100%"}}>
              <div style={{fontSize: "10px", color: "#2B2B2B", lineHeight: "12px", marginTop: "5px"}}>{current.title}</div>
              <span style={{fontSize: "20px", fontWeight: "bold"}}>{current.data}</span><span style={{fontSize: "10px", color: "grey", marginLeft: "3px"}}>{current.label}</span>
            </div>
          </div>
        )
      })

      let friendsData = [ {id: "v1", imgSrc: "/img/account/friend1.jpg", name: "Daniela Gutierrez", desc: "Health Champion"},
                          {id: "v2", imgSrc: "/img/account/friend2.jpg", name: "Boonsri Prinya", desc: "Mother Nature’s BFF"},
                          {id: "v3", imgSrc: "/img/account/friend3.jpg", name: "Kuba Al Habi", desc: "Carbon Conqueror"}
                        ]

      let showFriends = friendsData.map( current => {
        let friendStyle = current.id === "v2" ? {display: "flex", width: "100", borderTop: "1px #ddd solid", borderBottom: "1px #ddd solid", padding: "2px 0px"} : {display: "flex", width: "100", padding: "2px 0px"}
        return(
          <div style={friendStyle} key={current.id}>
            <div style={{width: "55px", padding: "1px", borderRadius: "50%", display: "flex", alignItems: "center"}}>
              <img width="100%" src={current.imgSrc} alt="pic" />
            </div>
            <div style={{flex: 1, paddingLeft: "10px", display: "flex", alignItems: "center"}}>
              <div style={{display: "flex", flexDirection: "column"}}>
              <span style={{fontSize: "12px", fontWeight: "bold"}}>{current.name}</span>
              <span style={{color: "grey", fontSize: "10px"}}>{current.desc}</span>
              </div>
            </div>
            <div style={{width: "25px"}} className="align-items-center">
              <img width="100%" src="/icon.png" alt="sustainaby icon" style={{filter: "grayscale(100%)", opacity: "0.5"}} />
            </div>
            <div style={{width: "25px"}} className="align-items-center">
              <ArrowForwardIos style={{fill: "#ddd"}} />
            </div>
          </div>
        )
      })

      let backgroundStyle = {}
      backgroundStyle.WebkitBackgroundSize =  "cover"
      backgroundStyle.MozBackgroundSize = "cover"
      backgroundStyle.OBackgroundSize = "cover"
      backgroundStyle.backgroundSize = "cover"
      backgroundStyle.overflow = "inherit"
      backgroundStyle.background = "url(/img/account/pov1.jpg) center top / cover no-repeat";

      return (
        <div className="col-12 row boxShadow" style={{fontSize: "22px", fontWeight: "semi-bold", fontFamily: "'Raleway', sans-serif"}}>

          <div className="col-3 hidden-md-down" style={backgroundStyle} />

          <div className="col-12 col-lg-9 row " style={{backgroundColor: "white", padding: "5px", alignItems: "flex-start", display: "flex"}} >

            <div className="col-4 hidden-sm-down" style={{padding: "5px 20px 25px 20px"}}>
                <h2 style={{fontSize: "22px"}} >Small Choices, Big Impacts</h2>
                <p style={{fontSize: "12px", color: "#2B2B2B"}} >
                Every time you buy a sustainable product, you’re making a huge difference. We’re here to help you reach your goals! You can set your ideal monthly environmental impact goal in four categories, then we’ll help you track how well you’re doing. To read more about how SustainaBuy works, click the “Learn More” link below.
                </p>
                <Button variant="outlined" component={Link} to="/method" size="small" style={{marginTop: "20px"}}>
                  Learn More
                </Button>
            </div>
            <div className="col-12 col-md-4 row" style={{padding: "5px"}}>
                <h2 style={{fontSize: "22px"}}>By the Numbers</h2>
                <div className="col-12 row" style={{ fontFamily: "'Raleway', sans-serif"}}>
                {showBoxes}
                </div>
            </div>
            <div className="col-12 col-md-4 row" style={{padding: "5px"}}>
                <div className="col-6">
                  <h2 style={{fontSize: "22px"}}>Vs. Friends</h2>
                </div>
                <div className="col-6 justify-content-end">
                  <div>
                  <Button disabled variant="outlined" component={Link} to="/method" color="secondary" size="small" style={{margin: "15px 5px 0px 0px"}}>
                    View All
                  </Button>
                  </div>
                </div>
                <div className="col-12">
                  {showFriends}
                </div>
            </div>
          </div>
        </div>

      );

}

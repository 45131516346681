import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


export default function Home(props) {

      return (

        <>
        <div style={{flex: 'auto'}} />

        <footer style={{display: 'flex', zIndex: 998, flexShrink: 0, width: '100%', padding: '10px 20px 15px 20px', justifyContent: 'center', alignItems: "center", backgroundColor: "#1A77B1"}}>
              <div className="hidden-sm-down col-12" style={{height: "60px"}}>
                <div className="col-12 row justify-content-center">
                  <div className="col-12 col-md-10 row">
                    <div style={{height: "65px", color:"white", maxWidth: "350px"}}>
                      <Button component="a" href="http://business.edf.org/ecommerce" size="small" style={{height: "65px", color:"white", maxWidth: "350px", overflow: "hidden", textTransform: "none"}}>
                        <div className="row">
                          <div className="col-12">
                            <img src="/footerLogo.png" style={{height: "30px"}} alt="an initiative of edf business logo"/>
                          </div>
                          <div className="col-12">
                            <p style={{fontSize: "8px"}}>257 Park Avenue South, New York, NY 10010<br />
                            Copyright © 2020 Environmental Defense Fund. All Rights Reserved.
                            </p>
                          </div>
                        </div>
                      </Button>
                    </div>
                    <div className="hidden-sm-down" style={{flex:1, display: "flex", justifyContent: "flex-end", paddingRight: "10px"}}>
                      <Button component={Link} to="/method" style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                        Impact Methodology
                      </Button>
                      <a href="https://business.edf.org/contact/" className="align-items-center">
                        <Button style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                          Contact Us
                        </Button>
                      </a>
                      <a href="https://www.edf.org/donate-online" className="align-items-center">
                        <Button  style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                          How You Can Help
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
                </div>

                <div className="hidden-md-up col-12">
                <div className="col-12 row ">
                    <div className="col-12">
                      <Button component={Link} to="/method" style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                        Impact Methodology
                      </Button>
                    </div>
                    <div className="col-12">
                      <a href="https://business.edf.org/contact/" className="align-items-center">
                        <Button style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                          Contact Us
                        </Button>
                      </a>
                    </div>
                    <div className="col-12">
                      <a href="https://www.edf.org/donate-online" className="align-items-center">
                        <Button  style={{color: "white", fontWeight: "bold", textTransform: "capitalize"}}>
                          How You Can Help
                        </Button>
                      </a>
                    </div>
                    <div className="col-12" style={{height: "65px", color:"white", maxWidth: "350px"}}>
                      <Button component="a" href="http://business.edf.org/ecommerce" style={{height: "65px", color:"white", maxWidth: "350px", overflow: "hidden", textTransform: "none"}}>
                        <div className="row">
                          <div className="col-12">
                            <img src="/footerLogo.png" style={{height: "30px"}} alt="an initiative of edf business logo"/>
                          </div>
                          <div className="col-12">
                            <p style={{fontSize: "8px", overflow: "hidden"}}>257 Park Avenue South, New York, NY 10010<br />
                            Copyright © 2020 Environmental Defense Fund. All Rights Reserved.
                            </p>
                          </div>
                        </div>
                      </Button>
                    </div>
                  </div>
                  </div>

            </footer>

        </>
      );

}

import React from 'react';
import ImpactChart from './impactChart';

export default function Bottombox(props) {

      let purchasesData = [ {id: "p1", imgSrc: "/img/account/prod1.png", title: "Name Brand Eco Laundry Detergent", desc: "64 fl oz"},
                            {id: "p2", imgSrc: "/img/account/prod2.jpg", title: "Organic Green Chili Salsa", desc: "8.4 fl oz"},
                            {id: "p3", imgSrc: "/img/account/prod3.jpg", title: "Cotton Printed T-Shirt", desc: "Men's Large"},
                            {id: "p4", imgSrc: "/img/account/prod4.png", title: "Name Brand Hydrating Night Cream", desc: "1.2 fl oz"},
                          ]

      let items = purchasesData.map( currentItem => {

        return(
          <div key={currentItem.id} className="col-12" style={{display: "flex", borderBottom: "1px #ddd solid", padding: "5px 0px"}} >
              <div style={{width: "55px", padding: "1px", borderRadius: "50%"}}>
                <img width="100%" src={currentItem.imgSrc} alt="pic" />
              </div>
              <div style={{flex: 1}}>
                <span style={{fontSize: "12px", fontWeight: "bold"}}>{currentItem.title}</span><br />
                <span style={{fontSize: "12px", color: "grey"}}>{currentItem.desc}</span>
              </div>
          </div>
        )
      })


      return (
        <div className="col-12 row" style={{fontSize: "22px", fontWeight: "semi-bold", fontFamily: "'Raleway', sans-serif", marginBottom: "25px"}}>

            <div className="col-12 col-xl-6 row boxShadow " style={{height: "100%", overflow: "hidden"}}>
              <div className="col-12" style={{padding: "5px 10px", backgroundColor: "white"}}>

                <div className="col-12" style={{display: "flex"}}>
                  <div style={{flex: 1, fontSize: "22px"}} className="">
                    <h2 style={{fontSize: "22px", marginBottom: "0px"}} >Your Environmental Impact History</h2>
                    <p style={{fontSize: "12px", color: "grey"}}>January 2020 - June 2020</p>

                  </div>
                  <div style={{width: "180px"}} className="align-items-center">
                    <img src="/img/account/impactRange.png" alt="low to high" width="100%" />
                  </div>
                </div>

                <div className="col-12">
                  <ImpactChart />
                </div>

              </div>
            </div>


            <div className="col-12 col-xl-6 hidden-lg-down" style={{paddingLeft: "20px"}}>

              <div className="col-12 row boxShadow" style={{ height: "100%", padding: "5px 10px"}}>
                <div className="col-12" style={{display: "flex"}}>
                  <div style={{flex: 1, fontSize: "22px"}} className="align-items-center">
                    <h2 style={{fontSize: "22px"}} >Recent Purchases</h2>
                  </div>
                  <div style={{width: "200px"}} className="align-items-center">
                    <img src="/img/impact_lowtohigh.png" alt="low to high" width="100%" />
                  </div>
                </div>
                <div className="col-12 row" style={{paddingTop: "15px"}}>
                    {items}
                </div>
              </div>
              </div>

          </div>
      );
}

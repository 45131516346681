import React, {useRef, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {PhotoCamera} from '@material-ui/icons';

export default function VisualSearch(props) {

      let [showItem, setShowItem] = useState(false)
      let [mediaStream, setMediaStream] = useState(false)
      let [showError, setShowError] = useState(false)

      useEffect(() => {
        async function enableStream() {
          try {
            let video = vidRef.current
            video.style.width =  window.document.width + 'px';
            video.style.height = window.document.height + 'px';
            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');

            var constraints = { audio: false, video: { facingMode: "environment" } }

            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            vidRef.current.srcObject = stream;
            setMediaStream(stream);
          } catch(err) {
            setShowError(true)
            // Removed for brevity
          }
        }

        if (!mediaStream) {
          enableStream();
        } else {
          return function cleanup() {
            mediaStream.getTracks().forEach(track => {
              track.stop();
            });
          }
        }
      }, [mediaStream]);


      const vidRef = useRef(null);

      /*
      <div style={{flex: 1}}>
          <div style={{ margin: "5px 0px 2px 0px"}}>
            <span style={{fontSize: "10px", color: "grey", textTransform: "uppercase", letterSpacing: "2px"}}>Brand Name</span>
          </div>
          <div style={{ width: "100%", overflow: "hidden"}}>
            <span style={{fontSize: "20px", fontWeight: "normal"}}>Yellow Mustard</span>
          </div>
          <div style={{ height: "18px", width: "100%", overflow: "hidden", marginBottom: "3px"}}>
            <span style={{fontSize: "8px", color: "grey"}}>00 fl oz</span>
          </div>
          <div className="align-items-center" style={{ height: "15px", width: "100%", overflow: "hidden", marginTop: "10px"}}>
            <img src="/img/starRating.png" height="12px" alt="sustainableFlag" />
            <span style={{fontSize: "12px", color: "grey"}}>1,345</span>
          </div>
          <div style={{ height: "18px", width: "100%", overflow: "hidden", marginTop: "3px"}}>
            <span style={{fontSize: "10px", fontWeight: "bold", color: "#1ECB40"}}>ENV. IMPACT: 2</span>
          </div>
      </div>
      */

      if (showError){
        return (
          <div className=" col-12" style={{padding: "20px"}}>

            <h2>Visual Search</h2>
            <p>Visit this page in Safari on your iPhone to test visual search.</p>

          </div>
        )
      }

      return (
        <div className="col-12 row" style={{position: "relative"}} >
            <video ref={vidRef} style={{objectFit: "cover", position:"fixed", width: "100vw", height: "100vh", right: 0, left: 0, top: 0, bottom: 0, zIndex: 998}} id="video"></video>

            {showItem &&
              <div style={{position:"absolute", right: 10, left: 10, top: 10, zIndex: 999}}>

                <div className="justify-content-center" style={{ padding: "7px", flexDirection: "row"}}>

                  <div style={{backgroundColor: "rgba(255, 255, 255, 0.9)", display: "flex", borderRadius: "5px"}}>
                    <div className="align-items-center"  style={{width: "75px", padding: "3px"}}>
                        <img src="/img/products/main/yellow.png" style={{height: "65px"}} alt="cart" />
                    </div>


                    <div style={{width: "160px"}} className="align-items-center">
                      <div>
                        <div style={{ width: "100%", overflow: "hidden"}}>
                          <span style={{fontSize: "22px", fontWeight: "normal"}}>Yellow Mustard</span>
                        </div>
                        <div className="align-items-center" style={{ height: "15px", width: "100%", overflow: "hidden"}}>
                          <img src="/img/starRating2.png" height="12px" alt="sustainableFlag" />
                          <span style={{fontSize: "12px", color: "grey"}}>1,345</span>
                        </div>
                        <div style={{ height: "18px", width: "100%", overflow: "hidden"}}>
                          <span style={{fontSize: "10px", fontWeight: "bold", color: "#1ECB40"}}>ENV. IMPACT: 2</span>
                        </div>
                      </div>
                    </div>

                    <div className="align-items-center justify-content-center"  style={{width: "100px"}}>
                      <Button component={Link} to={'/ip/2702'} variant="contained" color="secondary" size="small">
                        View Item
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            }

            {!showItem &&
            <div className="justify-content-center" style={{position:"fixed", right: 10, left: 10, top: 70, zIndex: 999}}>
                <Button onClick={ ()=> setShowItem(true) } variant="contained" color="primary" >
                  <PhotoCamera />
                  Search
                </Button>
            </div>
            }

        </div>
      );

}

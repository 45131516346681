import React, {useState} from 'react';
import Slider from './slider';
import ScoreColor from '../../data/scoreColor.json'

export default function ImpactChart(props) {

      let [chartSelected, setChartSelected] = useState("overall")

      let data = {
              "overall": { janScore: 9, febScore: 6, marScore: 7, aprScore: 5, mayScore: 3, junScore: 2 },
              "climate": { janScore: 8, febScore: 6, marScore: 9, aprScore: 6, mayScore: 3, junScore: 2 },
              "chemical": { janScore: 9, febScore: 7, marScore: 5, aprScore: 5, mayScore: 3, junScore: 2 },
              "water": { janScore: 9, febScore: 5, marScore: 6, aprScore: 4, mayScore: 3, junScore: 2 }
      }

      let janScore = data[chartSelected].janScore
      let febScore = data[chartSelected].febScore
      let marScore = data[chartSelected].marScore
      let aprScore = data[chartSelected].aprScore
      let mayScore = data[chartSelected].mayScore
      let junScore = data[chartSelected].junScore

      return (
        <div className="row">

            <div className="col-12" style={{marginTop: "10px"}}>
              <button onClick={ ()=> setChartSelected("overall") } className={chartSelected === "overall" ? "impactChart-btn impactChart-selcted" : "impactChart-btn"} >
                Overall
              </button>

              <button onClick={ ()=> setChartSelected("climate") } className={chartSelected === "climate" ? "impactChart-btn impactChart-selcted" : "impactChart-btn"}>
                Climate
              </button>

              <button onClick={ ()=> setChartSelected("chemical") } className={chartSelected === "chemical" ? "impactChart-btn impactChart-selcted" : "impactChart-btn"}>
                Chemical
              </button>

              <button onClick={ ()=> setChartSelected("water") } className={chartSelected === "water" ? "impactChart-btn impactChart-selcted" : "impactChart-btn"}>
                Water
              </button>
            </div>

            <div className="col-12 row" style={{display: "flex", marginTop: "20px"}}>

              <div style={{width: "200px"}}>
                <div className="col-12">
                    <Slider />
                </div>
              </div>

              <div  style={{flex: 1, marginTop: "35px", marginLeft: "-60px"}}>
                <div style={{height: "150px", display: "flex", alignItems: "flex-end"}}>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( janScore/10 * 100) + "%", backgroundColor: ScoreColor[janScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{janScore}</span>
                      </div>
                    </div>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( febScore/10 * 100) + "%", backgroundColor: ScoreColor[febScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{febScore}</span>
                      </div>
                    </div>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( marScore/10 * 100) + "%", backgroundColor: ScoreColor[marScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{marScore}</span>
                      </div>
                    </div>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( aprScore/10 * 100) + "%", backgroundColor: ScoreColor[aprScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{aprScore}</span>
                      </div>
                    </div>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( mayScore/10 * 100) + "%", backgroundColor: ScoreColor[mayScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{mayScore}</span>
                      </div>
                    </div>
                    <div style={{flex: 1, height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                      <div style={{height: ( junScore/10 * 100) + "%", backgroundColor: ScoreColor[junScore], width: "50%", position: "relative"}}>
                        <span style={{width: "100%", position: "absolute", top: -30, display: "flex", justifyContent: "center", fontWeight: "bold"}}>{junScore}</span>
                      </div>
                    </div>
                </div>
                <div style={{borderTop: "1px grey solid", display: "flex", color: "grey", fontSize: "14px", paddingTop: "5px"}}>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      JAN
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      FEB
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      MAR
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      APR
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      MAY
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                      JUN
                    </div>
                </div>
              </div>

            </div>


        </div>
      );
}

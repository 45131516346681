import React from 'react';
import Button from '@material-ui/core/Button';

export default function LargeCard(props) {

  let {current} = props
  let imgSrc = props.current && props.current.imgSrc ? props.current.imgSrc  : ""

  return(
        <div className="col-12 col-lg-6 row"  style={{padding: "10px 10px"}}>
          <div className="col-12 row" style={{backgroundColor: "white", borderRadius: "2px", border: "1px #dadada solid"}}>
            <div className="col-12 col-md-7 image-wrapper" style={{height: "300px", overflow:"hidden"}}>
              <img src={imgSrc} alt={current.title} style={{overflow: "hidden", objectFit: "cover"}} />
            </div>
            <div className="col-12 col-md-5 align-items-center" style={{padding: "10px 15px", minHeight: "200px"}}>
              <div>
                <span style={{fontSize: "24px"}}>{current.title}</span>
                <p style={{margin:"10px 0px", fontSize: "12px", opacity: "87%"}}>
                  {current.desc}
                </p>
                <Button variant="outlined" color="primary" size="small" disabled >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>

      );

}

import React from 'react';
import PovSearch from '../util/povSearch'
import LargeCard from '../util/cards/largeCard'
import BackgroundPOV from '../util/backgroundPOV'
//import { Link} from 'react-router-dom';
import LargeCardsData from '../../data/largeCards.json'
import ShopCategory from './shopCategory'

export default function Home(props) {

      return (
        <div className="row">

            <BackgroundPOV />

            <div className="col-12 justify-content-center" style={{margin: "35px 0px 20px 0px"}}>
              <img src="/whiteLogo.png" className="homepagePOVLogo" alt="logo"/>
            </div>

            <div className="col-12 row justify-content-center" style={{margin: "10px 0px 20px 0px"}}>
              <PovSearch />
            </div>

            <div className="col-12 row justify-content-center" style={{padding: "20px 10px"}}>
              <div className="col-12 col-md-10 row">
                <LargeCard current={LargeCardsData["card1"]} />
                <LargeCard current={LargeCardsData["card2"]} />

                <ShopCategory />

                <LargeCard current={LargeCardsData["card3"]} />
                <LargeCard current={LargeCardsData["card4"]} />

              </div>
            </div>



        </div>
      );

}
